'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.contacts.controller:ContactLocationsCtrl

 # @description

###
class ContactLocationsCtrl
  ### @ngInject ###
  constructor:(
    id
    $mdDialog
    ContactManager
    $q
    _
  ) ->
    @progress = true
    @model = {}

    init = =>
      @id = id
      @fields = ContactManager.getLocationForm()

      @load = load
      @add = add
      @remove = remove
      @cancel = cancel
      @submit = submit

      @load()
    ##########

    add = ->
      @progress = true
      $q.when(ContactManager.addLocation(@id, @model))
        .then =>
          @load()

    remove = (contactId) ->
      @progress = true
      $q.when(ContactManager.removeLocation(@id, contactId))
        .then =>
          @load()

    load = ->
      ContactManager.getLocations(@id)
        .then (data) =>
          @items = _.filter data ,(item) -> !item.deletedAt?
          @progress = false
          @form = {}


    cancel = ->
      $mdDialog.cancel()

    submit = ->
      console.log 'submit'


    init()
angular
  .module('mundoAdmin.contacts')
  .controller 'ContactLocationsCtrl', ContactLocationsCtrl
